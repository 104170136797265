

/* @media screen and (min-width: 1250px){ */
    .main-layout {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgb(34, 34, 34); 

    /* background-image: linear-gradient(135deg, black 35%, rgb(184, 190, 192) 50%, black 70%); */
    /* overflow-y: hidden; */
    /* background-color: rgb(34, 34, 34); */
}

.content-container {
    position: absolute;
    top: 0vh;
    left: 0vw;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.0);
    overflow-y: scroll;
    overflow-x: hidden;
}
/* } */
