#about {
    background-color: rgb(34, 34, 34); 
    position: relative;
    top: 4em;
    min-height: 85vh;
    width: 100vw;
    /* border: 2px solid red; */
}

.about-page {
    position: relative;
    background-color: transparent;
    width: 90%;
    color: rgb(0, 120, 255);
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 2em 2em 2em 2em;
    transition: opacity 1s;
    transition-delay: 1s;
    -webkit-transition-delay: 1s;
    -moz-transition-delay: 1s;
    -o-transition-delay: 1s;
}

.about-function-declaration, 
.return-declaration, 
.about-div-begin, 
.about-header, 
.about-paragraph, 
.paragraph, 
.closing-paragraph, 
.closing-div, 
.closing-return, 
.closing-about, 
.exporting-about,
.about-page {
    opacity: 0;
}

.about-function-declaration {
    transition: opacity 1s;
    transition-delay: 6s;
    -webkit-transition-delay: 6s;
    -moz-transition-delay: 6s;
    -o-transition-delay: 6s;
}

.return-declaration {
    transition: opacity 1s;
    transition-delay: 7s;
    -webkit-transition-delay: 7s;
    -moz-transition-delay: 7s;
    -o-transition-delay: 7s;
    margin-left: 2em;
}

.about-div-begin {
    transition: opacity 1s;
    transition-delay: 8s;
    -webkit-transition-delay: 8s;
    -moz-transition-delay: 8s;
    -o-transition-delay: 8;
    margin-left: 3em;
}

.about-header {
    transition: opacity 1s;
    transition-delay: 9s;
    -webkit-transition-delay: 9s;
    -moz-transition-delay: 9s;
    -o-transition-delay: 9s;
    margin-left: 4em;
}

.about-paragraph {
    transition: opacity 1s;
    transition-delay: 10s;
    -webkit-transition-delay: 10s;
    -moz-transition-delay: 10s;
    -o-transition-delay: 10s;
    margin-left: 4em;
}

.paragraph {
    transition: opacity 1s;
    transition-delay: 11s;
    -webkit-transition-delay: 11s;
    -moz-transition-delay: 11s;
    -o-transition-delay: 11s;
    margin-left: 5em;
}


.closing-paragraph {
    transition: opacity 1s;
    transition-delay: 12s;
    -webkit-transition-delay: 12s;
    -moz-transition-delay: 12s;
    -o-transition-delay: 12s;
    margin-left: 4em;
}

.closing-div {
    transition: opacity 1s;
    transition-delay: 13s;
    -webkit-transition-delay: 13s;
    -moz-transition-delay: 13s;
    -o-transition-delay: 13s;
    margin-left: 3em;
}

.closing-return {
    transition: opacity 1s;
    transition-delay: 14s;
    -webkit-transition-delay: 14s;
    -moz-transition-delay: 14s;
    -o-transition-delay: 14s;
    margin-left: 2em;
}

.closing-about {
    transition: opacity 1s;
    transition-delay: 15s;
    -webkit-transition-delay: 15s;
    -moz-transition-delay: 15s;
    -o-transition-delay: 15s;
}

.empty-paragraph {
    display: none;
}

.exporting-about {
    transition: opacity 1s;
    transition-delay: 16s;
    -webkit-transition-delay: 16s;
    -moz-transition-delay: 16s;
    -o-transition-delay: 16s;
}

.about-visible {
    visibility: visible;
    opacity: 1;
}

@keyframes arrow {
    0% {color: white; font-size: 1em;}
    12.5% {font-size: 1.5em;}
    20% {color: yellow;}
    25% {font-size: 1em;}
    37.5% {font-size: 1.5em;}
    40% {color: rgb(80, 255, 120);}
    50% {font-size: 1em;}
    60% {color: rgb(80, 255, 255);}
    62.5% {font-size: 1.5em;}
    75% {font-size: 1em;}
    80% {color:rgb(0, 120, 255);}
    87.5% {font-size: 1.5em;}
    100% {color: white; font-size: 1em;}
}
.scroll-arrow a {
    
    /* margin-left: 45vw; */
    text-align: center;
    
    color: transparent;
    line-height: 0.1em;
    animation-name: arrow;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-delay: 18s;
    cursor: default;
}

.scroll-arrow {
    position: absolute;
    display: block;
    width: 40px;
    /* margin: auto; */
    bottom: 1em;
    left: 2em;
}

.scroll-arrow a {
    text-decoration: none;
    scroll-behavior: smooth;
}

span {
    position: relative;
}

span p {
    display: inline;
    transition: font-size 0.3s, color 0.2s;
    line-height: 1.2em;
    font-size: 1.1em;
}

span p:nth-child(4n + 1):hover {
    color: chartreuse;
}
span p:nth-child(4n + 2):hover {
    color: rgb(206, 18, 80);
}
span p:nth-child(4n + 3):hover {
    color: rgb(24, 209, 200);
}
span p:hover {
    color: orchid;
    cursor: default;
}

.dark-blue-text {
    color:rgb(0, 120, 255);
}

.light-blue-text {
    color: rgb(140, 217, 252);
}

.white-text {
    color: white;
}

.pink-text {
    color: rgb(167, 57, 218);
}

.light-yellow-text {
    color: rgb(250, 250, 186);
}

.yellow-text {
    color: yellow;
}

.grey-text {
    color: grey;
}

.orange-text {
    color: rgb(190, 102, 70);
}

@media screen and (max-width: 300px) {
    .scroll-arrow {
        display: none;
    }
}
@media screen and (min-width: 900px) {
    #about {
        position: relative;
        top: -11em;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .about-page {
        position: relative;
        top: 7em;
        /* top: 2em; */
        left: 10vw;
        background-color: transparent;
        width: 70vw;
        color: rgb(0, 120, 255);
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 2em 2em 2em 2em;
        transition: opacity 1s;
        transition-delay: 5s;
        -webkit-transition-delay: 5s;
        -moz-transition-delay: 5s;
        -o-transition-delay: 5s;
    }

    .empty-paragraph {
        display: visible;
    }
    
    .scroll-arrow a {
        
        margin-left: 1vw;
        text-align: center;
        
        color: transparent;
        line-height: 0.1em;
        animation-name: arrow;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-delay: 18s;
        cursor: default;
    }

    .scroll-arrow {
        position: absolute;
        display: block;
        width: 40px;
        /* margin: auto; */
        bottom: 1em;
        left: 2em;
    }

    .scroll-arrow a {
        text-decoration: none;
        scroll-behavior: smooth;
    }

    .scroll-arrow a:hover {
        cursor: pointer;
    }

    span {
        position: relative;
    }

    span p {
        display: inline;
        transition: font-size 1s, color 0.5s;
        line-height: 1.2em;
        font-size: 2vh;;
    }

}

@media screen and (min-width: 1800px) {
    .about-page {
        top: 7em;
    }
}

@media screen and (min-width: 2600px) {
    #about {
        top: -18em;
    }
    .about-page {
        top: 15em;
    }
    span p {
        font-size: 3em;
    }
}