.projects-wrapper {
    position: relative;
    min-height: 100vh;
    margin-top: 3em;
    margin-left: 0;
    width: 100vw;
    min-height: 100vh;
}

.projects-wrapper h2 {
    text-align: center;
    font-size: 2em;
}


.projects-header-wrapper {
    padding-top: 5em;
    text-align: center;
    cursor: default;
    height: 50px;
}

.projects-header-wrapper h2   {
    display: inline;
    font-size: 2em;
    transition: color 0.5s, font-size 0.5s;
}

.projects-header-wrapper h2:hover,
.header-bracket-ending >  h2:hover {
    color: darkorchid;
}
.projects {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-template-columns: 50% 50%;
    top: 5em;
    left: 0;
    width: 95vw;
    margin: auto;
}

hr {
    width: 90vw;
}

.project {
    justify-self: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.project-left {
    width: 100%;
}

.project-right {
    width: 100%;
    position: relative;
}

.project-content-wrapper {
    margin-bottom: 4em;
}

.header-bracket-ending {
    position: relative;
    display: block;
    margin: auto;
    width: 1em;
    margin-top: 15em;
}

.header-bracket-ending h2 {
    text-align: center;
    transition: color 1s, font-size 1s;
    cursor: default;
}

.project-logo {
    height: 10em;
    width: 15em;
    justify-self: center;
    display: block ;
    margin: auto;
}

.tech-wrapper {
    margin: 2em;
}

.tech-logo {
    width: 4em;
    vertical-align: middle;
    margin: 2em 2em;
}

.project-links {
    position: relative;
    width: 100%;
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.project-links > div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.project-links a {
    text-decoration: none;
    justify-self: center;
}

.in-text-link {
    text-decoration: none;
    transition: color 1s;
}

.in-text-link:hover {
    color: crimson;
}

.external-link p:hover {
    cursor: pointer !important;
}

@media (min-width: 900px) {
    .project {
        flex-direction: row;
        width: 75%;
        margin: auto;
    }

    .project p {
        font-size: 1em;
    }

    .project-logo {
        width: 90%;
        height: auto;
    }
    .project-links {
        position: absolute;
        bottom: 1em;
    }
}

@media (min-width: 1250px) {
    .projects-header-wrapper h2,
    .header-bracket-ending h2 {
        font-size: 3em;
    }

    .projects {
        gap: 2em;
    }
    .project {
        flex-direction: row;
        width: 75%;
        margin: auto;
        gap: 2em;
    }

    .project p {
        font-size: 1em;
    }

    .project-logo {
        width: 90%;
        height: auto;
    }

    
}

@media (min-width: 1800px) {
    .projects-header-wrapper h2,
    .header-bracket-ending h2  {
        font-size: 3.5em;
    }
    .projects {
        gap: 2em;
    }
    .project {
        flex-direction: row;
        width: 75%;
        margin: auto;
        gap: 2em;
    }
    

    .project p {
        font-size: 1.5em;
    }

    .project-logo {
        width: 90%;
        height: auto;
    }

    .tech-wrapper {
        margin: 4em;
    }
    
    .tech-logo {
        width: 7em;
        vertical-align: middle;
        margin-left: 2em;
    }
}