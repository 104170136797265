.main-header {
    
    font-size: 3rem;
    position: absolute;
    top: 40vh;
    padding-top: 0px;
    padding-left: 0px;
    /* margin: 0; */
    margin-left: 40vw;
    width: 80%;
    height: 2.5em;
    text-align: left;
    /* background: linear-gradient(90deg, rgb(86, 97, 109) 10%, rgb(86, 97, 109) 20%, rgb(0, 0, 0)); */
    /* border-radius: 0 0 50px 0; */
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    transition-duration: 2s;
    transition-property: margin-left, top, opacity;
    transition-delay: 2s, 2s, 1s;
    -webkit-transition-delay: 2s, 2s, 1s;
    -moz-transition-delay: 2s, 2s, 1s;
    -o-transition-delay: 2s, 2s, 1s;
}

.main-header h1 {
    color: rgb(0, 120, 255);
    /* margin-top: -0.1em; */
    top: -80px;
    left: 2vw;
    position: absolute;
    display: inline;
    font-family: 'Fredericka the Great', 'Algerian';
    pointer-events: painted;
    transition: color 1s linear;
    cursor:default;
}

.main-header h1:hover {
    color: red;
}

.main-header-visible {
    margin-left: 0;
    top: 0;
    opacity: 1;
}

@media screen and (min-width: 2600px) {
    .main-header {
        font-size: 6em;
    }
    .main-header h1 {
        top: -160px;
    }
}
