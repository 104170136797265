.hamburger {
    position: fixed;
    width: 35px;
    right: 10px;
    top: 5px;
    cursor: pointer;
    border-color: grey;
    border-radius: 10px;
    z-index: 6;
}

.bar1, .bar2, .bar3 {
    width: 90%;
    height: 5px;
    margin: 6px auto;
    background-color: #333;
    transition: 0.5s;
}

.change .bar1 {
    transform: rotate(-45deg) translate(-9px, 5px);
    -webkit-transform: rotate(-45deg) translate(-9px, 5px);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    transform: rotate(45deg) translate(-9px, -7px);
    -webkit-transform: rotate(45deg) translate(-9px, -7px);
}

.main-navigation {
        position: fixed;
        top: -83px;
        width: 100vw;
        margin-left: -100vw;
        z-index: 5;
        transition: margin-left 1s;
    }

    .main-navigation-open {
        margin-left: 0;
    }

    .main-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 25px;
    text-align: center;
    bottom: 3em;
    height: 100vh;
    max-height: 100%;
    padding-top: 3em;
    margin-top: 8em;
    margin-bottom: 3em;
    background-color: rgba(75, 75, 75, 0.9);
    transition: color 1s;
    list-style: none;
    transition: color 1s;
    overflow-y: scroll;
}

nav hr {
    width: 80%;
    border: 1px solid rgb(71, 196, 201);
}

.main-menu a {
    text-decoration: none;
    color: rgb(0, 120, 255);
    font-weight: 700;
    font-size: 2rem;
    transition: color 1s;
}

.link-imitation {
    pointer-events: none;
}

.main-menu a:hover {
    color: red;
}


.main-menu img {
    width: 25px;
    height: 25px;
}

@media  screen and (min-width: 900px) {
    .hamburger {
        display: none;
    }
    .main-navigation {
        position: sticky;
        position: -webkit-sticky;
        top: 50px;
        margin-left: 0;
        width: 70vw;
        z-index: 5;
        opacity: 0;
        transition: opacity 2s;
        transition-delay: 4s;
        -webkit-transition-delay: 4s;
        -moz-transition-delay: 4s;
        -o-transition-delay: 4s;
        overflow-y: unset;
        /* border: 3px solid green; */
    }

    .main-navigation hr {
        display: none;
    }

    .main-navigation-visible {
        visibility: visible;
        opacity: 1;
    }

    .main-menu {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 1em;
    height: 3em;
    margin-top: 8em;
    margin-bottom: auto;
    border-radius: 0 0 15px 0;
    background-color: rgba(75, 75, 75, 0.5);
    list-style: none;
    overflow: auto;
}


.main-menu a {
    text-decoration: none;
    color: rgb(0, 120, 255);
    font-weight: 700;
    font-size: 1.2rem;
    /* font-family: 'Niconne'; */
    margin-left: 2em;
    transition: color 1s;    
}

.link-imitation {
    pointer-events: none;
}



.main-menu img {
    width: 25px;
    height: 25px;
}
}

.image-link{
    position: relative;
    display: inline;
    margin: 1em;
}

@media screen and (min-width: 900px) {
    .main-navigation {
        width: 90vw;
    }
}

@media screen and (min-width: 1200px) {
    .main-navigation {
        width: 70vw;
    }
}

@media screen and (min-width: 2600px) {
    .main-menu {
        margin-top: 13em;
        height: 5.4em;
    }

    .main-menu a {
        font-size: 3.2em;
    }
}