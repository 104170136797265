.portfolio-footer {
    position: relative;
    top: 4em;
    text-align: center;
}

.portfolio-footer h2 {
    position: relative;
    display: inline;
    font-size: 2em;
    transition: color 0.5s;
}

.footer-header h2:hover {
    color: darkorchid;
    cursor: default;
}

.footer-header {
    margin-top: 9em;
    padding-top: 5em;
    margin-bottom: 4em;
    position: relative;
}

.footer-bracket-ending {
    display: block;
    position: relative;
    margin-bottom: 3em;
}
.footer-bracket-ending h2 {
    font-size: 2em;
}

.footer-bracket-ending h2:hover {
    color: darkorchid;
    cursor: default;
}

.footer-content {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 2em;
}

.footer-left {
    flex: 1;
    position: relative;
    width: 100%;
    color: rgb(0, 120, 255);
    display: flex;
    flex-direction: column;
    text-align: left;

}

.footer-left span {
    padding-left: 1em;
}

.footer-return-declaration {
    margin-left: 2em;
}

.footer-div-begin {
    margin-left: 3em;
}

.footer-header-left {
    margin-left: 3em;
}

.footer-paragraph {
    margin-left: 4em;
}

.footer-paragraph-text {
    margin-left: 5em;
}

.footer-center {
    flex: 1;
    position: relative;
}

.footer-center label {
    display: block;
    margin-bottom: 2em;
}

.message-paragraph {
    display: block;
    margin: 1em auto;
}

.text-input {
    color: lightgray;
    background-color: rgb(34, 34, 34);
    height: 2em;
    border-radius: 5px;
    font-size: 1em;
    padding-left: 1rem;
    width: 90%;
    /* border-color: rgb(0, 120, 255); */
    border-color: grey;
    transition: border-color 0.3s;
}

.text-input:hover {
    border-color: crimson;
}

label span {
    display: block;
}

.message-input {
    color: lightgray;
    background-color: rgb(34, 34, 34);
    height: 20em;
    border-radius: 5px;
    font-size: 1rem;
    padding-left: 1rem;
    border-color: grey;
    width: 90%;
    transition: border-color 0.3s;
}

.message-input:hover {
    border-color: crimson;
}


.submit-button {
    background-color: rgb(34, 34, 34);
    border-radius: 10px;
    margin: 2em auto;
    transition: border 0.2s;
}

.submit-button:hover{
    border-color: crimson;
}
.footer-right {
    flex: 1;
    position: relative;
}

textarea {
    resize: none;
    width: 90%;
}

@media screen and (min-width: 900px) {
    .footer-content {
        flex-direction: row;
    }
}

@media screen and (min-width: 1250px) {
    .footer-header h2 {
        font-size: 3em;
    }

    label span {
        display: inline;
    }

    .footer-bracket-ending h2 {
        font-size: 3em;
    }
}

@media screen and (min-width: 1800px) {
    .footer-header h2 {
        font-size: 3.5em;
    }

    .footer-bracket-ending h2 {
        font-size: 3.5em;
    }
}